import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/sv';
import 'dayjs/locale/fi';
import 'dayjs/locale/da';
import 'dayjs/locale/nb';
import 'dayjs/locale/nl';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import weekday from 'dayjs/plugin/weekday';
import utc from 'dayjs/plugin/utc';
import isBetween from 'dayjs/plugin/isBetween';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(localizedFormat);
dayjs.extend(customParseFormat);
dayjs.extend(weekday);
dayjs.extend(utc);
dayjs.extend(isBetween);
dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(timezone);

export function setDayjsLocale(locale) {
  dayjs.locale(locale);
}

// used in getFormattedDate
function setDayJsLocale(lang) {
  const languageToLocale = {
    sv: 'sv',
    fi: 'fi',
    nb: 'nb',
    da: 'da',
    en: 'en',
  };

  const locale = languageToLocale[lang] || 'en';
  dayjs.locale(locale);
}

export function getFormattedDate(date, lang) {
  // Set the locale based on the user's language preference
  setDayJsLocale(lang);

  // Format the date in the full style. Day.js will use the set locale automatically.
  return dayjs(date).format('dddd, LL'); // 'LL' is a localized format in Day.js
}

// Example of usage:
// const formattedDate = getFormattedDateTimeDayJs(new Date(), 'sv');

export const getFormattedTime = (time, lang) => {
  setDayJsLocale(lang); // Set the locale

  return dayjs(time).format('LT'); // 'LT' formats the time based on the user locale
};

export const convertUtcToVenueTime = (utcTime, venueTimezone) => {
  return dayjs(utcTime).tz(venueTimezone).format();
};

// Not used atm
export const isDST = (date: dayjs.Dayjs) => {
  return date.utcOffset() !== date.month(0).utcOffset();
};

export const quartileToDate = (quartile, baseDate = dateUtil()) => {
  let coercedQuartile = parseInt(quartile, 10);
  if (Number.isNaN(coercedQuartile)) {
    return dateUtil();
  }

  return dateUtil(baseDate)
    .set('hour', 6)
    .set('minute', 0)
    .add(15 * coercedQuartile, 'minutes');
};

const MINUTES_IN_QUARTILE = 15;
const QUARTILES_IN_DAY = 96;

const getQuartiles = (date) => {
  const quartileOneUnix = dateUtil(date).set('hour', 6).set('minute', 0).valueOf();
  const milliseconds = date.valueOf() - quartileOneUnix;
  const seconds = milliseconds / 1000;
  const minutes = seconds / 60;
  return Math.floor(minutes / MINUTES_IN_QUARTILE);
};

export const dateToQuartile = (date: Dayjs) => {
  if (!date.isValid()) {
    return null;
  }

  let quartiles = getQuartiles(date);
  if (quartiles < 0) {
    quartiles += QUARTILES_IN_DAY;
  }

  return quartiles;
};

const dateUtil = dayjs;

export default dateUtil;
