import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { createWrapper } from 'next-redux-wrapper';
import { bookingApi } from '../services/bookingApi';
import { s3Api } from '../services/s3Api';
import paramsSlice from '../services/paramsSlice';

// Import redux-persist functions
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

const persistConfig = {
  key: 'root',
  storage,
  // You can also specify a list of reducers to persist, for example:
  // whitelist: ['someReducer']
};

export function makeStore() {
  // Combine reducers
  const rootReducer = combineReducers({
    [s3Api.reducerPath]: s3Api.reducer,
    [bookingApi.reducerPath]: bookingApi.reducer,
    params: paramsSlice,
  });

  // Use persistReducer to wrap the combined rootReducer
  const persistedReducer = persistReducer(persistConfig, rootReducer);

  const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          // Ignore these action types
          ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE'],
        },
      }).concat([s3Api.middleware, bookingApi.middleware]),
    devTools: true,
  });

  // Enable the store to persist
  store.__PERSISTOR = persistStore(store);

  return store;
}

export const wrapper = createWrapper(makeStore, { debug: false });
