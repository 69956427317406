import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

const texts = {
  norrlandsgatanlunch: {
    pre: { en: '' },
    post: {
      en: 'APP APP - KONTANTFRITT VAR DET HÄR! Jo, det stämmer. Hos oss går det utmärkt att betala med appen eller tillkalla kypare för kortbetalning.Vi är med andra ord en helt kontantfri restaurang.',
    },
  },
  'se-hq-it': {
    pre: {
      en: 'Ganska välkommen till Demo Sverige! 🥳 Göteborgs vackraste restaurang (?)😚\nLämna gärna en kommentar i eran bokning om Vi kan göra något för att erat besök ska bli lite extraordinärt trevligt. ♥️ Ni når oss enklast via eran bokningsbekräftelse om det skulle vara något!\nSkriv gärna en kommentar om något särskilt vi behöver veta om ert besök, ni kan också skriva sms till oss efter er bokning, vi svarar där ❤️ VI SES SNART ⭐️',
    },
    post: { en: 'En annan text' },
  },
  'se-sthlm-hammarby': {
    pre: {
      en: 'Varmt välkommen kära gäster till Pinchos i Hammarby Sjöstad  🥰  \nSkriv gärna en kommentar om något särskilt vi behöver veta om ert besök, ni kan också skriva sms till oss efter er bokning, vi svarar där ❤️ \nVI SES SNART ⭐️',
    },
    post: {
      en: 'Varmt välkommen kära du till Pinchos i Hammarby Sjöstad  🥰  \nFör att komma i kontakt med oss vänligen svara i detta SMS  💌  \nEfter klockan åtta FRE LÖR höjer vi musiken i lokalen 👯‍♀️🍾 \nVi älskar att ni tar med era barn till oss, MEN barnen bör sitta vid bordet och inte springa omkring. Det händer lätt olyckor 🤕\nTyvärr får man ej ta med barnvagnar utan barn i in till restaurangen PGA utrymningsskäl. \nObservera sittiden ❤️\nOBS ⚠️ Meddela oss om ni behöver barnstol vid bordet!\nVI SES SNART 🤩 ',
    },
  },
  'se-gbg-heden': {
    pre: {
      en: 'Oerhört välkommen till Pinchos Heden! 🥳 Göteborgs vackraste restaurang (?)😚\nLämna gärna en kommentar i eran bokning om Vi kan göra något för att erat besök ska bli lite extraordinärt trevligt. ♥️ Ni når oss enklast via eran bokningsbekräftelse om det skulle vara något!\nSkriv gärna en kommentar om något särskilt vi behöver veta om ert besök, ni kan också skriva sms till oss efter er bokning, vi svarar där ❤️ VI SES SNART ⭐️',
    },
    post: {
      en: 'APP APP - KONTANTFRITT VAR DET HÄR! Jo, det stämmer. Hos oss går det utmärkt att betala med appen eller tillkalla kypare för kortbetalning. Vi är med andra ord en helt kontantfri restaurang. Har ni med barnvagn? Meddela oss gärna via sms då vi har begränsad plats i entrén. Pga utrymningsvägar kan barnvagn ej tas med till bordet. Om ni får avhopp så vill vi bli informerade senast 24h innan ankomst. Vi ses ❤️',
    },
  },
  'no-oslo-rosenkratz': {
    pre: {
      en: 'App app app! Som gjest hos oss kan du booke bord, sjekke ut menyen, bestille mat og drikke, og til slutt betale - alt via Pincho Nation appen. Appen finner du i App Store eller Google Play. ',
    },
    post: {
      en: 'App app app! Som gjest hos oss kan du booke bord, sjekke ut menyen, bestille mat og drikke, og til slutt betale - alt via Pincho Nation appen. Appen finner du i App Store eller Google Play. ',
    },
  },
  'se-karlstad-inre-hamn': {
    pre: {
      en: 'Vi har stängt Pinchos inre hamn för säsongen. Öppnar igen till våren. Välkomna till Pinchos City istället. Mvh Pinchos Karlstad ',
    },
    post: { en: '' },
  },
  'se-jonkoping-ostra-stor': {
    pre: { en: '' },
    post: {
      en: 'Tada! Kolla igenom så allt stämmer och spara gärna bekräftelsen! Vi är en kontantfri restaurang. Tänk på att vi inte har någon bar att hänga i så kom gärna på bokad tid! Vi har inte plats för barnvagnar vid borden på grund av säkerhetskäl men finns plats att ställa av dom. Vi ses! <3',
    },
  },
  'se-umea-centrum': {
    pre: { en: null },
    post: {
      en: 'Spara gärna smset med bekräftelsen. Om du vill nå oss går det bra att svara via sms. Välkomna till Pinchos Umeå på Kungsgatan 48. Glöm inte att vi är en helt kontantfri restaurang.',
    },
  },
  'se-vaxjo-stor': {
    pre: { en: '' },
    post: {
      en: 'Spara gärna smset med bekräftelsen. Om du vill nå oss går det bra att svara via sms. Ladda gärna ner vår app inför besöket!',
    },
  },
  'se-lulea-stor': {
    pre: {
      en: 'Spara gärna smset med bekräftelsen. Om du vill nå oss går det bra att svara via sms. Välkomna till Pinchos Luleå. Glöm inte att vi är en helt kontantfri restaurang. Observera att köket stänger kl 21.00 och restaurangen 22.00',
    },
    post: {
      en: 'Spara gärna smset med bekräftelsen. Om du vill nå oss går det bra att svara via sms. Välkomna till Pinchos Luleå. Glöm inte att vi är en helt kontantfri restaurang. Observera att köket stänger kl 21.00 och restaurangen kl 22.00',
    },
  },
  'se-varberg-drottning': {
    pre: { en: '' },
    post: { en: 'Välkomna!App app.. Kontantfri restaurang! 🍿' },
  },
  'se-lund-martens': {
    pre: {
      en: 'App app app... glöm inte att vi är helt kontantfria, alltså endast kortbetalning.Varmt välkomna!',
    },
    post: {
      en: 'App app app... glöm inte att vi är helt kontantfria, alltså endast kortbetalning.Varmt välkomna!',
    },
  },
  Konferansrum: {
    pre: { en: 'Välj personer för antalet timmar, 1 person = 1 timme' },
    post: { en: 'Tack för bokningen, glöm inte bort att avboka om du ställer in mötet.' },
  },
  'se-alingsas-lilla-torget': {
    pre: {
      en: 'Pinchos Alingsås är en kontantfri restaurang.\n\nHEJ TÄNK PÅ ATT BARNVAGNAR INTE ALLTID GÅR ATT TA MED TILL BORDET, OCH HAR NI MINDRE BARN MED I SÄLLSKAPET MEDDELA GÄRNA OSS DET TACK PÅ FÖRHAND\n, VARMT VÄLKOMNA',
    },
    post: {
      en: 'APP APP - KONTANTFRITT VAR DET HÄR! Jo, det stämmer. Hos oss går det utmärkt att betala med appen eller tillkalla kypare för kortbetalning.Vi är med andra ord en helt kontantfri restaurang.',
    },
  },
  'dk-cph-axeltorv': {
    pre: {
      en: 'Når du har bekræftet din booking får du en sms med bekræftelse. Du kan besvare denne sms med spørgsmål eller kommentar til din booking. Vi glæder os til at se dig på Pincho Nation.',
    },
    post: {
      en: 'Når du har bekræftet din booking får du en sms med bekræftelse. Du kan besvare denne sms med spørgsmål eller kommentar til din booking. Vi glæder os til at se dig på Pincho Nation.',
    },
  },
  'se-falkenberg-stor': {
    pre: { en: 'Pinchos Falkenberg är en kontantfri resturang.' },
    post: {
      en: 'APP APP- KONTANTFRITT VAR DET HÄR!Jo, det stämmer. Hos går det betala med appen eller kypare för kortbetalning. Vi är med andra ord en helt kontantfri resturang.',
    },
  },
  'se-varnamo-jarnvagsgatan': {
    pre: { en: '' },
    post: { en: '' },
  },
  'se-vaxjo-stor2': {
    pre: { en: '' },
    post: {
      en: 'Spara gärna smset med bekräftelsen. Om du vill nå oss går det bra att svara via sms. Ladda gärna ner vår app inför besöket!',
    },
  },
  'se-gbg-magasin': {
    pre: { en: 'Pinchos Magasinsgatan är en kontantfri restaurang' },
    post: {
      en: 'APP APP - KONTANTFRITT VAR DET HÄR! Jo, det stämmer. Hos oss går det utmärkt att betala med appen eller tillkalla kypare för kortbetalning. Vi är med andra ord en helt kontantfri restaurang. 🍼 Barnvagn kan man ställa vid entrén inomhus men går alltså inte längre in än så. Har vagnen en löstagbar del så kan man ta med den på platsen ni bokat för den mindre. Tusen tack och ses då ❤️',
    },
  },
  'se-ovik-ny': {
    pre: { en: '' },
    post: {
      en: 'Spara gärna smset med bekräftelsen. Om du vill nå oss går det bra att svara via sms. Välkomna till Pinchos Övik. Glöm inte att vi är en helt kontantfri restaurang.',
    },
  },
  'se-angelholm-skol': {
    pre: {
      en: 'APP APP - KONTANTFRITT VAR DET HÄR! Jo, det stämmer. Vi är en helt kontantfri restaurang. Har ni med barnvagn? Meddela oss gärna via sms då vi har begränsad plats i vår vagnpark. Pga av utrymningsvägar kan barnvagn ej tas med till bordet. Vi ses ❤️',
    },
    post: {
      en: 'APP APP - KONTANTFRITT VAR DET HÄR! Jo, det stämmer. Hos oss går det utmärkt att betala med appen eller tillkalla kypare för kortbetalning.Vi är med andra ord en helt kontantfri restaurang. Har ni med barnvagn? Meddela oss gärna via sms då vi har begränsad plats i vår vagnpark. Pga av utrymningsvägar kan barnvagn ej tas med till bordet. Vi ses ❤️',
    },
  },
  'se-kristianstad-ostra-stor': {
    pre: {
      en: 'App app app... glöm inte att vi är helt kontantfria, alltså endast kortbetalning.Varmt välkomna!',
    },
    post: {
      en: 'App app app... glöm inte att vi är helt kontantfria, alltså endast kortbetalning.Varmt välkomna!',
    },
  },
  'se-kungsbacka-aranas-alle': {
    pre: { en: '' },
    post: { en: '' },
  },
  Exploria: {
    pre: {
      en: 'App App! Restaurangen stänger 20.00. Vid fler än 4 personer i samma sällskap så kommer Ni att delas upp på olika bord.  Vi på Pinchos är kontantfria! Meddela oss gärna om ni har önskemål om placering tex låga/höga bord eller barnstol eller liknande. OBS! Ange antalet för både vuxna och barn när ni bokar bord hos oss. Kram',
    },
    post: {
      en: 'På grund av rådande omständigheter kring Coronaviruset vill vi ge dig lite info om hur vi bidrar till ökad trygghet. Restaurangen stänger 20.15. Max 4 personer vid samma bord. Vi förhåller oss löpande till de anvisningar vi får från folkhälsomyndigheten. Personalen får daglig info om hur vi på Pinchos ska säkerställa att vi gör allt vi kan för att du ska känna dig trygg. Så känn er varmt välkomna och njut lite extra mycket hos oss. för frågor kontakta oss via sms. Kram',
    },
  },
  'se-sundsvall-esplanaden': {
    pre: { en: '' },
    post: {
      en: 'Spara gärna smset med bekräftelsen. Om du vill nå oss går det bra att svara via sms. Ladda gärna ner vår app inför besöket! Observera att Pinchos Sundsvall är en kontantfri restaurang.\n',
    },
  },
  'se-visby-sodertorg': {
    pre: { en: '' },
    post: {
      en: 'Hör gärna av er om ni har några funderingar eller om ni blir fler eller färre. Ni har bordet i 2 timmar . Ladda gärna ner vår app inför ert besök hos oss! :)  Vi är kontantfria!',
    },
  },
  'no-oslo-fred-olsens': {
    pre: {
      en: 'App app app! Som gjest hos oss kan du booke bord, sjekke ut menyen, bestille mat og drikke, og til slutt betale - alt via Pincho Nation appen. Appen finner du i App Store eller Google Play. ',
    },
    post: {
      en: 'App app app! Som gjest hos oss kan du booke bord, sjekke ut menyen, bestille mat og drikke, og til slutt betale - alt via Pincho Nation appen. Appen finner du i App Store eller Google Play. ',
    },
  },
  'se-vasteras-stora-torget': {
    pre: {
      en: 'Pinchos Västerås tar endast bokningar för matgäster! Barn under 16 år besöker oss endast i sällskap med vuxen :) Stängningstider för köket - Tis -Tors 21:00 Fre-Lör 22:00 Sön 21:00\nVi är en djurfri restaurang. Skriv gärna om ni har problem med trappor eller höga bord då vi har flera våningar.',
    },
    post: {
      en: '\tTada! Kolla igenom så att allt stämmer och spara smset så hörs vi här om ni har funderingar eller om det blir ändringar! =)',
    },
  },
  'se-hbg-bruks': {
    pre: { en: 'Pinchos Helsingborg CENTRUM är en kontantfri restaurang.' },
    post: {
      en: 'Tusen tack för er bokning på Pinchos Bruksgatan! Observera att vi är en kontantfri restaurang. Varmt välkomna in i cirkusen.',
    },
  },
  'se-uddevalla-tradgards': {
    pre: {
      en: 'No te olvides: Kom ihåg att även boka plats för barn i alla åldrar i sällskapet. Har ni med barnvagn? Vi har begränsad plats för parkering av dessa, så meddela oss gärna! Barnvagnar kan ej tas med till bordet pga utrymningsvägar. Barn under 15 år får inte besöka oss utan vuxet sällskap. Ett steg kvar, sen kan festen börja!<3',
    },
    post: { en: '' },
  },
  'se-hbg-soder': {
    pre: {
      en: 'Har ni med en barnvagn? Meddela oss gärna via sms då vi har begränsad plats. Observera att vi är en kontantfri restaurang. Er sittningstid är 2 timmar.',
    },
    post: {
      en: 'Tusen tack för er bokning på Pinchos Södergatan! ❤️ Har ni med en barnvagn? Meddela oss gärna via sms då vi har begränsad plats. Observera att vi är en kontantfri restaurang. Er sittningstid är 2 timmar. Varmt välkomna in i cirkusen 🎪',
    },
  },
  'se-kiruna-forenings': {
    pre: { en: 'Observera att Pinchos är en Kontantfri Restaurang' },
    post: {
      en: 'APP APP - Hej hej hej hej  KONTANTFRITT VAR DET HÄR! Jo, det stämmer. Hos oss går det utmärkt att betala med appen eller tillkalla kypare för kortbetalning.Vi är med andra ord en helt kontantfri restaurang.',
    },
  },
  'se-motala-poppel': {
    pre: { en: '' },
    post: {
      en: 'APP APP - KONTANTFRITT VAR DET HÄR! Jo, det stämmer. Hos oss går det utmärkt att betala med appen eller tillkalla kypare för kortbetalning.Vi är med andra ord en helt kontantfri restaurang.',
    },
  },
  'se-kalmar-kaggens': {
    pre: {
      en: 'Spara gärna smset med bekräftelsen. Om du vill nå oss går det bra att svara via sms. Glöm inte att vi är en helt kontantfri restaurang.',
    },
    post: {
      en: 'Spara gärna smset med bekräftelsen. Om du vill nå oss går det bra att svara via sms.  Välkomna till Pinchos i Kalmar. Glöm inte att vi är en helt kontantfri restaurang.',
    },
  },
  'se-gavle-centralplan': {
    pre: {
      en: 'Kom ihåg att meddela om ni har behov av barnstol eller lågt bord. Vi har begränsad plats för barnvagn.',
    },
    post: { en: '' },
  },
  'se-are-kabinbanevagen': {
    pre: {
      en: 'APP APP, kontantfritt var det här! Det går utmärkt att betala via appen eller tillkalla kypare för kortbetalning; Vi är med andra ord en helt kontantfri restaurang. Har ni frågor inför ert besök kan ni svara på bokningsbekräftelsen, så återkommer vi så fort vi kan <3 VARMT VÄLKOMNA',
    },
    post: {
      en: 'APP APP, kontantfritt var det här! Det går utmärkt att betala via appen eller tillkalla kypare för kortbetalning; Vi är med andra ord en helt kontantfri restaurang. Har ni frågor inför ert besök kan ni svara på bokningsbekräftelsen, så återkommer vi så fort vi kan <3 VARMT VÄLKOMNA🍿',
    },
  },
  '': {
    pre: { en: '2' },
    post: { en: '1' },
  },
  'se-sodertalje-storgatan': {
    pre: { en: 'Pinchos Heden är en kontantfri restaurang. ' },
    post: {
      en: 'APP AP - KONTANTFRITT VAR DET HÄR!Jo, det stämmer. Hos oss går det utmärkt att betala med appen eller tillkalla kypare för kortbetalning.Vi är med andra ord en helt kontantfri restaurang.',
    },
  },
  'se-ljungby-ringvagen': {
    pre: { en: null },
    post: {
      en: 'Varmt välkomna till oss på Pinchos Ljungby. Om du vill nå oss går det bra att svara via sms. Glöm inte att vi är en helt kontantfri restaurang. Köket stänger 20.30. 😊\n',
    },
  },
  'se-skelleftea-ny': {
    pre: {
      en: 'Välkomna till Pinchos Skellefteå! Spara gärna smset med bekräftelsen när ni bokat. Om ni vill nå oss går det bra att svara via sms :) Glöm inte att vi är en helt kontantfri restaurang! Observera att köket stänger 20.00 och restaurangen kl 21 :) ',
    },
    post: {
      en: 'Tack för din bokning och välkomna till Pinchos Skellefteå! Spara gärna smset med bekräftelsen. Om ni vill nå oss går det bra att svara via sms :) Glöm inte att vi är en helt kontantfri restaurang! Observera att köket stänger 20.00 och restaurangen kl 21 :) ',
    },
  },
  'se-lidkoping-fabriks': {
    pre: { en: '' },
    post: {
      en: 'Tada! Kolla igenom så att allt stämmer och spara smset så hörs vi här om ni har funderingar eller om det blir ändringar! =) Vi är en kontantfri restaurang:)',
    },
  },
  'se-norrkoping-kungs': {
    pre: {
      en: 'Tada! Kolla igenom så att allt stämmer och spara smset så hörs vi här om ni har funderingar eller om det blir ändringar! Pinchos Norrköping en kontantfri restaurang =)',
    },
    post: {
      en: 'Tada! Kolla igenom så att allt stämmer och spara smset så hörs vi här om ni har funderingar eller om det blir ändringar! Pinchos Norrköping en kontantfri restaurang =)',
    },
  },
  'se-trelleborg-algatan': {
    pre: {
      en: 'App app app... glöm inte att vi är helt kontantfria, alltså endast kortbetalning.Varmt välkomna!',
    },
    post: {
      en: 'App app app... glöm inte att vi är helt kontantfria, alltså endast kortbetalning.Varmt välkomna!',
    },
  },
  'se-malmo-generals': {
    pre: {
      en: 'Tada! Observera att vi är en kontantfri restaurang! Kolla igenom så att allt stämmer och spara smset så hörs vi här om ni har funderingar eller om det blir ändringar! =)',
    },
    post: {
      en: 'Tada! Observera att vi är en kontantfri restaurang! Kolla igenom så att allt stämmer och spara smset så hörs vi här om ni har funderingar eller om det blir ändringar! =)',
    },
  },
  'se-ystad-hamn': {
    pre: { en: null },
    post: {
      en: 'Tada! Nu är allt bokat och klart 😊 Ni missar väl inte våra Happenings i kommande månad? 😍',
    },
  },
  'se-malmo-kalende': {
    pre: {
      en: 'Tada! Observera att vi är en kontantfri restaurang! Kolla igenom så att allt stämmer och spara smset så hörs vi här om ni har funderingar eller om det blir ändringar! =)',
    },
    post: {
      en: 'Tada! Observera att vi är en kontantfri restaurang! Kolla igenom så att allt stämmer och spara smset så hörs vi här om ni har funderingar eller om det blir ändringar! =)',
    },
  },
  'se-salen-salfjallstorget': {
    pre: { en: 'Observera att vi är en kontantfri restaurang.' },
    post: { en: 'APP APP - KONTANTFRITT VAR DET HÄR! Välkomna till Pinchos Sälen' },
  },
  'se-eskilstuna-rademarcher': {
    pre: { en: '' },
    post: {
      en: 'Tack för din bokning! Har du något särskilt önskemål eller har med barnvagn? Meddela oss gärna i förväg via sms. Vi ses - varmt välkomna!',
    },
  },
  'se-enkoping-kall': {
    pre: {
      en: 'Meddela oss gärna vid specifika önskemål t.ex barnstol. OBS! Vi är en kontantfri restaurang',
    },
    post: {
      en: 'Tack för din bokning, svara på bekräftelse smset om ni önskar kontakta oss. Observera att köket är öppet till kl 20.30 och restaurangen stänger kl: 21.00 ',
    },
  },
  'se-harnosand-nybro': {
    pre: { en: '' },
    post: {
      en: 'Vad roligt att ni vill komma till oss! Om du vill nå oss går det bra att svara via sms. App App! Pinchos Härnösand är en kontantfri restaurang! Observera sittiden.',
    },
  },
  'se-halmstad-stor': {
    pre: { en: '' },
    post: {
      en: 'App app app... glöm inte att vi är helt kontantfria, alltså endast kortbetalning.Varmt välkomna!',
    },
  },
  'se-ostersund-thomegrand': {
    pre: { en: '' },
    post: {
      en: 'Vad roligt att ni vill komma till oss! Om du vill nå oss går det bra att svara via sms. App App! Pinchos Östersund är en kontantfri restaurang! Observera sittiden.',
    },
  },
  'se-linkoping-stor': {
    pre: { en: '' },
    post: {
      en: 'Tada! Kolla igenom så att allt stämmer och spara smset så hörs vi via sms eller mail (info@linkoping.pinchos.se) om ni har funderingar eller om det blir ändringar! =)',
    },
  },
  'se-pitea-stor': {
    pre: {
      en: 'Spara gärna smset med bekräftelsen. Om du vill nå oss går det bra att svara via sms. Välkomna till Pinchos Piteå. Glöm inte att vi är en helt kontantfri restaurang. Observera att köket stänger 21.30 :) och restaurangen 22.00 :)',
    },
    post: {
      en: 'Spara gärna smset med bekräftelsen. Om du vill nå oss går det bra att svara via sms. Välkomna till Pinchos Piteå. Glöm inte att vi är en helt kontantfri restaurang. Observera att köket stänger 21.30 :) Och restaurangen 22.00 :)',
    },
  },
  'se-oskarshamn-vastra-torg': {
    pre: {
      en: 'Tada! Kolla igenom så att allt stämmer och spara smset så hörs vi här om ni har funderingar eller om det blir ändringar! =)',
    },
    post: {
      en: 'Tada! Kolla igenom så att allt stämmer och spara smset så hörs vi här om ni har funderingar eller om det blir ändringar! =)',
    },
  },
  'se-nykoping-norrkoping': {
    pre: { en: '' },
    post: {
      en: ' Tada! Kolla igenom så att allt stämmer och spara smset så hörs vi här om ni har funderingar eller om det blir ändringar! =) Observera att Pinchos Nyköping är en kontantfri restaurang.',
    },
  },
  'dk-cph-fiole-rosen': {
    pre: {
      en: 'Når du har bekræftet din booking får du en sms med bekræftelse. Du kan besvare denne sms med spørgsmål eller kommentar til din booking. Vi glæder os til at se dig på Pincho Nation.',
    },
    post: {
      en: 'Når du har bekræftet din booking får du en sms med bekræftelse. Du kan besvare denne sms med spørgsmål eller kommentar til din booking. Vi glæder os til at se dig på Pincho Nation.',
    },
  },
  'se-vastervik-stor': {
    pre: { en: '' },
    post: {
      en: 'Tada! Kolla igenom så att allt stämmer och spara smset så hörs vi här om ni har funderingar eller om det blir ändringar! =)',
    },
  },
  'se-hudiksvall-trad': {
    pre: { en: null },
    post: { en: null },
  },
  'se-uddevalla-foodtruck': {
    pre: { en: '' },
    post: {
      en: 'Tada! Kolla igenom så att allt stämmer och spara smset så hörs vi här om ni har funderingar eller om det blir ändringar! =) Välkomna till Pinchos i Ålidhem Centrum',
    },
  },
  'se-vetlanda-bangardsgatan': {
    pre: {
      en: 'Tada! Kolla igenom så att allt stämmer och spara smset så hörs vi här om ni har funderingar eller om det blir ändringar! =) ',
    },
    post: {
      en: 'Tada! Kolla igenom så att allt stämmer och spara smset så hörs vi här om ni har funderingar eller om det blir ändringar! =) ',
    },
  },
  'se-karlstad-vastra-torg': {
    pre: { en: '' },
    post: {
      en: 'APP APP - KONTANTFRITT VAR DET HÄR!Jo, det stämmer. Hos oss går det utmärkt att betala med appen eller tillkalla kypare för kortbetalning.Vi är med andra ord en helt kontantfri restaurang. Har ni med barnvagn? Meddela oss gärna via sms då vi har begränsad plats i entrén. Pga utrymningsvägar kan barnvagn ej tas med till bordet. Vi ses ❤️',
    },
  },
  'fi-turku-aurakatu': {
    pre: {
      en: 'Pincho Nationissa voit maksaa kortilla tai sovelluksella. Ilmoitathan jos seurueessanne on pieniä lapsia.',
    },
    post: {
      en: 'Muistathan että meillä voit maksaa sovelluksella tai korttimaksulla. Käteistä emme käytä ravintolassamme ollenkaan. ',
    },
  },
  'se-borlange-borganas': {
    pre: {
      en: 'Tada! Vi är en kontantfri restaurang & tar endast betalt med kort eller med appen. Meddela om ni har barn med i sällskapet. Tänk på att barnvagn inte alltid går att ta med till bordet. :)',
    },
    post: {
      en: 'Tada! Vi är en kontantfri restaurang & tar endast betalt med kort eller med appen. Meddela om ni har barn med i sällskapet. Tänk på att barnvagn inte alltid går att ta med till bordet. :)',
    },
  },
  'se-falun-trotz': {
    pre: {
      en: 'Tada! Vi är en kontantfri restaurang & tar endast betalt med kort eller med appen. Meddela gärna om det är barn i sällskapet. :)',
    },
    post: {
      en: 'Tada! Vi är en kontantfri restaurang & tar endast betalt med kort eller med appen. Meddela gärna om det är barn i sällskapet. :) ',
    },
  },
  'se-sthlm-norrlands': {
    pre: {
      en: 'Hej, och välkomna till Pinchos Norrlandsgatan kära gäster 🥰\n\nOm ni har några små med er i barnvagn skicka gärna ett sms till oss så vi kan hitta ett bord som passar alla, annars finns det plats att lämna barnvagnen på övervåningen i mån av plats. Mindeåriga under 15 år skall alltid vistas i vuxet sällskap vid samma bord. Bokningar med mindeåriga är senast kl19:30 med en sittningstid upp till kl21.00 ✨\n\nEfter kl21.00 spelas lagom hög musik och belysningen sänks något för våran 18+ gäster 🍸\n\nPå helger håller vi oss rätten att avboka ditt bord efter en försening på 15min  utan undantag. Fre-Lör finns det inte möjlighet att byta bord utom det lämnas till guds händer (boknings systemet 🙃) och Hovmästarinna/Hovmästaren på plats. Har du önskemål av höga eller låga bord skriv gärna det så kommer vi att göra vårt bästa förr att du/ni får den bästa platsen samt upplevelsen vid er besök 🍿\n\nVI SES SNART⭐️\n\nMvh,\nPinchos Norrlandsgatan 🎪',
    },
    post: {
      en: 'Varmt välkomna till Pinchos Norrlandgatan 🥰\nOm ni har några frågor eller om något ändras i er bokning, svara på detta meddelande för att komma i kontakt med oss.\nBarn under 15 måste vara med åtminstone en vuxen ansvarig som sitter med vid bordet. \nEfter klockan 21.30 sänks belysningen och volymen höjs för våra 18+ gäster, barn är välkomna  innan denna tid! 🥂 \nVi ses!!! 🎪 ❤️',
    },
  },
  'se-katrineholm-hantverkare': {
    pre: {
      en: 'Du är snart klar med din bokning! Skriv gärna en kommentar om ni behöver barnstol, handikappsanpassat, plats för barnvagn eller liknande samt om ni skulle föredra att sitta på uteserveringen vid mån av plats!\nMvh\nCirkusdirektören med vänner\n\n\n',
    },
    post: {
      en: 'Tack för din bokning, skriv ett sms om ni behöver komma i kontakt med oss!\nMvh Cirkusdirektören med vänner\n\n',
    },
  },
  'se-landskrona-radhusgatan': {
    pre: {
      en: 'Vid behov av plats för barnvagn i lokalen och/eller barnstol, informera gärna i kommentarsfältet ovan 🤗 Observera att vi är en kontantfri restaurang. Varmt välkomna in i cirkusen. 🎪',
    },
    post: {
      en: 'Tusen tack för er bokning på Pinchos Landskrona! Vid förändringar i din bokning släng gärna iväg ett sms till oss här 🤗\nObservera att vi är en kontantfri restaurang samt har begränsade platser för barnvagnar inomhus. Varmt välkomna in i cirkusen. 🎪',
    },
  },
  'se-trollhattan-stor': {
    pre: {
      en: 'No te olvides: Pinchos Trollhättan är en kontantfri restaurang. Kom ihåg att även boka plats för barn i alla åldrar i sällskapet.🍿 Har ni med barnvagn? Vi har begränsad plats för parkering av dessa, så meddela oss gärna!❤️Barnvagnar kan ej tas med till bordet pga utrymningsvägar. Ett steg kvar, sen kan festen börja! Köket stänger 22❤️',
    },
    post: { en: null },
  },
  'se-uppsala-granby': {
    pre: {
      en: 'Vi är kontantfria. I er bokningsbekräftelse så kan ni se hur lång sittid ni har innan nästa sällskap kommer samt annan viktig info om bokningen. 🤗🌸',
    },
    post: {
      en: 'Tack för din bokning! 😃 OBSERVERA: Vi är en kontantfri restaurang. Alla barn (inklusive bebisar) räknas som egen person när ni bokar. Vi kan inte lova att det kommer att finnas plats för er barnvagn så försök gärna om det går att lämna denna utanför restaurangen. Vi vill också be er om hjälp att minimera lek och spring runtom restaurangen för allas bekvämlighet. Tack för att ni hjälper oss tillhanda hålla en trygg & säker miljö i vår restaurang. Hjärtligt välkomna till oss ❤️🎪 Pinchos Gränby\n \n',
    },
  },
  'se-gbg-gotaberg': {
    pre: { en: '' },
    post: {
      en: 'Välkomna till Pinchos Götabergsgatan! 🍿 Hos oss går det utmärkt att betala med appen eller tillkalla kypare för kortbetalning. Vi är en helt kontantfri restaurang. Välkomna ❤️',
    },
  },
  'se-norrtalje-bro': {
    pre: {
      en: 'Hej varmt välkomna a till oss i Pinchos Norrtälje, vi önskar er en härligt och trevligt vistelse.',
    },
    post: {
      en: 'Hej och välkomna till oss på Pinchos Norrtälje! Vi önskar er en härligt och trevligt vistelse. Är ni ett sällskap större än 8p behöver ni skicka en bokningsförfrågan . Vi kan inte garantera att ni kan sitta tillsammans om ni har flera bokningar.  Önskar ni komma i kontakt med oss kan ni ringa oss på vår telefon 0709261694 eller skicka ett meddelande via er bokning i appen. Vi tar ej emot några djur inne i vår restaurang med hänsyn till våra allergiska gäster. \nBarn under 15 år ska komma i vuxet sällskap om inget annat avtalats mellan vårdnadshavare och restaurangen. Varmt välkomna! ',
    },
  },
  'se-sthlm-fridhemsplan': {
    pre: {
      en: 'Varmt välkommen till Pinchos Fridhemsplan 🍷🍴\nKom ihåg att vi är en kontantfri restaurang.\nKalas eller stora barn sällskap är välkomna senast kl 16,00.\nStora sällskap accepteras endast efter förfrågan (boka ej flera bord).\nHundar är välkomna endast under visa dagar och tider.\nNi kan skriva till oss i appen efter er bokning, vi svarar alltid där.\n💚VI SES SNART⭐️',
    },
    post: {
      en: 'Hej, vad kul att ni vill komma till oss! 🍷🍴\nDitt bord är tillgängligt till 15 min efter din bokningstid, därefter avbokas det. \nPlacering sker automatiskt och under helgen eller när det är fullbokat är det tyvärr inte möjligt att byta bord. \nOBS! Gäster under 16år är välkomna endast i vuxet sällskap och sitter vid samma bord. (boka ej flera bord) Glöm ej att ha leg med. \nFör att vara respektfulla till alla våra gäster, tillåts inte att barnen springer eller lekar runt i restaurangen.\nGenom att bekräfta din bokning bekräftar du också att du har läst och accepterar våra regler och villkor. \nVi förbehåller oss rätten att neka bokningen om dessa regler och villkor inte respekteras. \nPincho Nation har inget ansvar vid förlust av personliga föremål\nVarmt Välkommen till Pinchos Fridhemsplan💚',
    },
  },
  'se-skovde-st-sigfrid': {
    pre: {
      en: 'No te olvides: Pinchos Skövde är en kontantfri restaurang. Kom ihåg att även boka plats för barn i alla åldrar i sällskapet. Har ni med barnvagn? Vi har begränsad plats för parkering av dessa, så meddela oss gärna!<3 Ett steg kvar, sen kan festen börja!',
    },
    post: { en: 'Varmt välkomna till Pinchos Skövde!' },
  },
  'se-uppsala-kloster': {
    pre: {
      en: 'Pinchos Uppsala City är en kontantfri  app restaurang. Kom ihåg att boka plats för barn i alla åldrar i sällskapet. Barnvagn kan ställas vid entrén inomhus men inte följa med till bordet, har vagnen en löstagbar del så kan man ta med den på platsen ni bokat för den lille! Tack och varmt välkomna!🍿',
    },
    post: { en: 'Varmt välkomna! ' },
  },
  'se-orebro-drottning': {
    pre: { en: '' },
    post: {
      en: 'För er info: Barn under 16 år måste komma i sällskap av vuxen. Öppettider för kök:  tisdag-torsdag: 16:30-21:00, fredag: 16:00-22:00, lördag: 14:00-22:00, söndag: 15:00- 20:30 \n',
    },
  },
  'se-karlskrona-ronneby': {
    pre: {
      en: 'Hej, vi är en kontantfri restaurang, vi har både höga och låga bord, det kan ju vara bra att känna till :)\n',
    },
    post: {
      en: 'Tack för din bokning! Pinchos stänger 22:00, om ni vill hänga kvar med oss efteråt är ni välkomna en trappa ner till puben! Vi ses!🍿🎈☺️',
    },
  },
  'fi-lahti-aleksanterinkatu': {
    pre: { en: null },
    post: {
      en: 'Huomioithan että varauksesi kesto on kaksi tuntia. Jos haluat pidentää varaustasi, olethan yhteydessä ravintolaan!  Tervetuloa! 🎪',
    },
  },
  'se-boras-lilla-bro': {
    pre: { en: '' },
    post: {
      en: 'Äntligen får vi ses som vanligt igen! Tagga oss gärna i sociala medier så vi får ta del av er tid hos oss 🥳 @pinchosboras @eatpinchos #pinchosboras ',
    },
  },
  'se-sthlm-folkunga': {
    pre: {
      en: 'Varmt välkommen till Pinchos Folkungagatan🍴\nKom ihåg att vi är en kontantfri restaurang.\nKalas eller stora barn sällskap är välkomna senast kl 16,00.\nStora sällskap accepteras endast efter förfrågan (boka ej flera bord)\nHundar är välkomna endast under visa dagar och tider.\nNi kan skriva till oss i appen efter er bokning, vi svarar alltid där.\n💜 VI SES SNART⭐️',
    },
    post: {
      en: 'Hej, vad kul att ni vill komma till oss! 🍷🍴\nDitt bord är tillgängligt till 15 min efter din bokningstid, därefter avbokas det. \nPlacering sker automatiskt och under helgen eller när det är fullbokat är det tyvärr inte möjligt att byta bord. \nOBS! Gäster under 16år är välkomna endast i vuxet sällskap och sitter på samma bord. (boka ej flera bord) Glöm ej att ha leg med. \nFör att vara respektfulla till alla våra gäster, tillåts inte att barnen springer eller lekar runt i restaurangen.\nGenom att bekräfta din bokning bekräftar du också att du har läst och accepterar våra regler och villkor. \nVi förbehåller oss rätten att neka bokningen om dessa regler och villkor inte respekteras. \nPincho Nation har inget ansvar vid förlust av personliga föremål\nVarmt Välkommen till Pinchos Folkungagatan💜\n',
    },
  },
  'no-porsgrunn-stor': {
    pre: { en: null },
    post: {
      en: 'Tadaaa! Vi takker og bukker for din reservasjon. Grunnet stor pågang gjør vi deg oppmerksom på din sittetid. Vennligst send oss en melding nedenfor dersom du ønsker å sitte lenger eller har spørsmål vedrørende din reservasjon. Vi sees!',
    },
  },
  'se-linkoping-storgatan': {
    pre: { en: 'Varmt välkomna till Pinchos Linköping! 🥳 \n' },
    post: {
      en: 'Tada 🥳 Varmt Välkomna in till Pinchos Linköping!\nKolla igenom så att allt stämmer och spara smset så hörs vi här om ni har funderingar eller om det blir ändringar! Pinchos Linköping är en kontantfri restaurang =)',
    },
  },
  'dk-hq-it': {
    pre: { en: null },
    post: {
      en: 'Hej 🥰 Når du har bekræftet din booking får du en sms med bekræftelse. Du kan besvare denne sms med spørgsmål eller kommentar til din booking 🍿🎪 Vi glæder os til at se dig på Pincho Nation Aalborg 🥳',
    },
  },
  'dk-randers-ostergrave': {
    pre: {
      en: 'Tak for din bordreservation 🍿🎪 Når du har bekræftet din booking får du en sms med bekræftelse. Vi glæder os til at se dig på Pincho Nation Randers 🥳',
    },
    post: {
      en: 'Tak for din bordreservation 🍿🎪  Vi glæder os til at se dig på Pincho Nation Randers 🥳. Du kan slette reservationen eller sende en besked til os om ændringer her: \n',
    },
  },
  'se-bollnas-nygatan': {
    pre: { en: null },
    post: { en: null },
  },
  'dk-aalborg-jomfruanegade': {
    pre: { en: null },
    post: {
      en: 'Hej 🥰 Når du har bekræftet din booking, får du en sms med bekræftelse. Du kan besvare denne sms med spørgsmål eller kommentar til din booking 🍿🎪 Vi glæder os til at se dig på Pincho Nation Aalborg. OBS køkkenet lukker klokken 22:30\n - Herefter er det kun til drinks.\n',
    },
  },
  'se-gbg-linne': {
    pre: { en: 'H\n' },
    post: {
      en: 'APP APP - KONTANTFRITT VAR DET HÄR! Jo, det stämmer. Hos oss går det utmärkt att betala med appen eller tillkalla kypare för kortbetalning. Vi är med andra ord en helt kontantfri restaurang. Har ni med barnvagn? Meddela oss gärna via sms då vi har begränsad plats i entrén. Pga utrymningsvägar kan barnvagn ej tas med till bordet. Vi ses ❤️',
    },
  },
  'se-sundbyberg-signal': {
    pre: { en: null },
    post: { en: null },
  },
  'no-fredrikstad-nygata': {
    pre: {
      en: 'App app app! Som gjest hos oss kan du booke bord, sjekke ut menyen, bestille mat og drikke, og til slutt betale - alt via Pincho Nation appen. Appen finner du i App Store eller Google Play. ',
    },
    post: {
      en: 'App app app! Som gjest hos oss kan du booke bord, sjekke ut menyen, bestille mat og drikke, og til slutt betale - alt via Pincho Nation appen. Appen finner du i App Store eller Google Play. ',
    },
  },
};

type BookingTexts = {
  pre: string | null;
  post: string | null;
};

export async function getBookingTexts(venueId, locale): Promise<BookingTexts> {
  const venueTexts = texts[venueId] || { pre: { en: null }, post: { en: null } };
  return {
    pre: venueTexts.pre[locale] || venueTexts.pre.en || null,
    post: venueTexts.post[locale] || venueTexts.post.en || null,
  };
}

export function useGetBookingTexts(locale) {
  const {
    query: { venueId },
  } = useRouter();

  const [texts, setTexts] = useState<BookingTexts>({ pre: null, post: null });

  useEffect(() => {
    getBookingTexts(venueId, locale).then((venueTexts) => setTexts(venueTexts));
  }, [locale, venueId]);

  return texts;
}
