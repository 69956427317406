const UPCOMING = 0;
const SEATED = 1;
const CANCELLED = 2;
const FORCED = 3;
const NEW = 4;
const REQUESTED = 5;
const DONE = 6;
const REFUSED = 7;
const NO_SHOW = 8;
const BLOCK = 9;
const PREPARED = 10;
const BAR_GUEST = 11;
const WAITLISTED = 12;

export const preDoneStates = [UPCOMING, SEATED, FORCED, NEW, REQUESTED, PREPARED, WAITLISTED, CANCELLED];

export const states = {
  UPCOMING,
  SEATED,
  CANCELLED,
  FORCED,
  NEW,
  REQUESTED,
  DONE,
  REFUSED,
  NO_SHOW,
  BLOCK,
  PREPARED,
  BAR_GUEST,
  WAITLISTED,
  [UPCOMING]: 'UPCOMING',
  [SEATED]: 'SEATED',
  [CANCELLED]: 'CANCELLED',
  [FORCED]: 'FORCED',
  [NEW]: 'NEW',
  [REQUESTED]: 'REQUESTED',
  [DONE]: 'DONE',
  [REFUSED]: 'REFUSED',
  [NO_SHOW]: 'NO_SHOW',
  [BLOCK]: 'BLOCK',
  [PREPARED]: 'PREPARED',
  [BAR_GUEST]: 'BAR_GUEST',
  [WAITLISTED]: 'WAITLISTED',
};
